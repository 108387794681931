import axios from "axios";
import { apiErrorHandler } from "./apiErrorHandler";

// Create axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_DEVELOPMENT_URL,
  headers: {
    accept: "*/*",
    Authorization:
      localStorage.getItem("__admin__token&") ||
      sessionStorage.getItem("__admin__token&") ||
      "", // Default to empty if no token is found
  },
});

// Response interceptor for error handling
axiosInstance.interceptors.response.use(
  (response) => response, // Return response directly if no error
  (error) => {
    // Check if the error is caused by cancellation
    if (axios.isCancel(error)) {
      // Optionally log the cancellation, if needed
      console.log("Request canceled:", error.message);
      return Promise.resolve(); // Suppress the cancellation error
    }

    // Centralized error handling for other errors
    const handledError = apiErrorHandler(error);
    return Promise.reject(handledError); // Reject to propagate other errors
  }
);

export default axiosInstance;

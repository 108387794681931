import React, { useState } from "react";
import css from '../style/Component/Nav.module.css';
import mainCss from "./../main.module.css"
import { Link, useMatch } from "react-router-dom";
import Logo from "./../assets/parking-bud-provider-assets/rent-your-parking/add-parking.png"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
} from 'reactstrap';

const _ = (classes) => {
  let s = "";
  classes.map((i) => (s += i + " "));
  return s;
};
function Navz() {

  const [isOpen, setIsOpen] = useState(false);
  const LoginPage = useMatch('/login')
  const testPage = useMatch('/test')
  // const rentYourSpacePage=useMatch('/rentyourspace')
  const dashboardPage = useMatch('/dashboard')

  const toggle = () => setIsOpen(!isOpen);


  return (
    <>{!testPage && !dashboardPage &&
      <div className={_(["sticky-top d-flex flex-row justify-content-center align-items-center  p-0 m-0"])}>
        <Navbar color="light" light expand="md" className={_(["d-flex flex-row navbar navbar-light bg-light shadow ", css.nav])}>
          <Link className={_(["d-flex flex-row justify-content-center align-items-center", css.logo_container])} style={{ textDecoration: 'none' }} to="/">
            <img src={Logo} className={_(["", css.logo_img])} alt="...img"></img>
            <div className={_(["ml-3", css.logo])}
            >Parking Bud</div>
          </Link>
          <NavbarToggler className={_(['ml-auto'])} onClick={toggle} />
          <Collapse className={_([" m-auto"])} isOpen={isOpen} navbar>
            <Nav className={_(["ml-3 mr-5 ", css.center_box, mainCss.font4, css.flx, mainCss.font_18])}>
              {!LoginPage && <Link to='/login-link' className={_(['', css.link_text, css.display_link])}>
                Log In
              </Link>}
            </Nav>
          </Collapse>
        </Navbar>
      </div>}
    </>
  );
}
export default Navz;

import React, { useState, useEffect, useCallback } from "react";
import axiosInstance from "../../utils/axiosInstance";
import NotificationContext from "../../components/notification/NotificationContext";
import { useNavigate } from "react-router-dom";

// Extract token from localStorage or sessionStorage
const token = localStorage.getItem("__admin__token&") || sessionStorage.getItem("__admin__token&") || null;

const DashboardContext = (props) => {
    const history = useNavigate();

    const [state, setState] = useState({
        id: null,
        first_name: "",
        last_name: "",
        email: "",
        profile_pic: null,
        phone_number: "",
        address_line_1: "",
        address_line_2: "",
        country: "",
        country_code: "",
        city: "",
        state: "",
        zip_code: "",
        timezone: "",
    });

    const [permis, setPermis] = useState({});
    const [contextData, setContextData] = useState();
    const [emailData, setEmailData] = useState();

    const [activeNotification, setActiveNotification] = useState();
    const [activeCount, setActiveCount] = useState(0);
    const [adminAccess, setAdminAccess] = useState();
    const [accessCount, setAccessCount] = useState(0);

    const [ID, setID] = useState(-1);

    // Fetch profile data
    const fetchProfile = useCallback(async () => {
        if (token != null) {
            try {
                const response = await axiosInstance.get("admin_dash/edit_admin_profile");
                setState(response.data.response.user);
                setID(response.data.response.user.id);
            } catch (err) {
                if (err?.message === "Network Error") {
                    sessionStorage.removeItem("__admin__token&");
                    localStorage.removeItem("__admin__token&");
                    history("/", { replace: true });
                    window.location.reload();
                }
                console.error(err);
            }
        }
    }, [history]);

    // Fetch permissions
    const fetchPermissions = useCallback(async () => {
        if (token != null) {
            try {
                const response = await axiosInstance.get("/permissions");
                setPermis(response.data.response);
            } catch (err) {
                console.error(err.response);
            }
        }
    }, []);

    // Fetch email permissions
    const fetchEmailPermissions = useCallback(async () => {
        if (token != null) {
            try {
                const response = await axiosInstance.get("admin_dash/email_permission");
                setEmailData(response.data.response.data);
            } catch (err) {
                console.error(err.response.data);
            }
        }
    }, []);

    // Fetch activity data
    const fetchAdminActivities = useCallback(async () => {
        const socket = new WebSocket(
            `${process.env.REACT_APP_BACKEND_DEVELOPMENT_URL_SOCKET}ws/admin_activites/`
        );

        socket.onopen = () => {
            if (socket.readyState === WebSocket.OPEN) {
                socket.send(JSON.stringify({ token }));
            }
        };

        socket.onmessage = (e) => {
            const data = JSON.parse(e.data);
            if (data.type === "notification") {
                setActiveCount(data.notifications_unread);
                setActiveNotification(data.notifications);
            }
        };

        return () => {
            socket.close();
        };
    }, []);

    // Fetch admin access notifications
    const fetchAdminAccess = useCallback(async () => {
        const socket = new WebSocket(
            `${process.env.REACT_APP_BACKEND_DEVELOPMENT_URL_SOCKET}ws/admin_access/`
        );

        socket.onopen = () => {
            if (socket.readyState === WebSocket.OPEN) {
                socket.send(JSON.stringify({ token }));
            }
        };

        socket.onmessage = (e) => {
            const data = JSON.parse(e.data);
            setAccessCount(data.notifications_unread);
            setAdminAccess(data.notifications);
        };

        return () => {
            socket.close();
        };
    }, []);

    // Fetch notifications
    const fetchAdminNotifications = useCallback(async () => {
        let socket;  // Declare socket outside the condition
    
        if (ID != -1) {
            socket = new WebSocket(
                `${process.env.REACT_APP_BACKEND_DEVELOPMENT_URL_SOCKET}ws/admin_notifications/${ID}/`
            );
    
            socket.onopen = () => {
                if (socket.readyState === WebSocket.OPEN) {
                    socket.send(JSON.stringify({ token }));
                }
            };
    
            socket.onmessage = (e) => {
                const data = JSON.parse(e.data);
                setContextData(data);
            };
        }
    
        // Cleanup function to close the socket when component is unmounted or ID changes
        return () => {
            if (socket) {
                socket.close();
            }
        };
    }, [ID]);
    

    // Separate useEffect hooks for each function

    useEffect(() => {
        fetchProfile();
    }, [fetchProfile]);

    useEffect(() => {
        fetchPermissions();
    }, [fetchPermissions]);

    useEffect(() => {
        fetchEmailPermissions();
    }, [fetchEmailPermissions]);

    useEffect(() => {
        fetchAdminActivities();
    }, [fetchAdminActivities]);

    useEffect(() => {
        fetchAdminAccess();
    }, [fetchAdminAccess]);

    useEffect(() => {
        fetchAdminNotifications();
    }, [fetchAdminNotifications]);

    return (
        <NotificationContext.Provider
            value={{
                data: contextData,
                profile: state,
                permis,
                activeCount,
                activeNotification,
                adminAccess,
                accessCount,
                emailData,
            }}
        >
            {props.children}
        </NotificationContext.Provider>
    );
};

export default DashboardContext;
